import { LOGIN_ROUTE } from 'constants/navigation';

export interface PermissionsLibrary {
  write_shop_open_status: boolean;
  read_shop_invoice: boolean;
  write_shop_commission_based_services: boolean;
  write_shop_petty_cash_options: boolean;
  write_shop_suppliers: boolean;
  write_shop_staff: boolean;
  write_shop_notification_preferences: boolean;
  read_shop_activity_log: boolean;
  write_shop_wholesale_account: boolean;
  read_shop_wholesale: boolean;
  write_shop_wholesale_order: boolean;
  read_shop_wholesale_order: boolean;
  read_shop_order: boolean;
  write_shop_order: boolean;
  read_shop_order_history: boolean;
  write_shop_product: boolean;
  write_shop_stock: boolean;
  read_shop_promotion: boolean;
  action_shop_pos_use_print: boolean;
  write_shop_pos_favourites: boolean;
  action_shop_pos_use_commission_service: boolean;
  action_shop_pos_use_manual_barcode: boolean;
  action_shop_pos_use_cashback: boolean;
  action_shop_pos_use_discount: boolean;
  write_shop_user_credit: boolean;
  action_shop_pos_use_voucher: boolean;
  write_shop_pos_void_items: boolean;
  action_shop_pos_use_refund: boolean;
  action_shop_pos_drawer_open_transaction: boolean;
  action_shop_pos_drawer_open_manual: boolean;
  action_shop_pos_use_petty_cash: boolean;
  read_shop_report_x: boolean;
  read_shop_report_s: boolean;
  read_shop_report_z: boolean;
  read_shop_report_sales: boolean;
  write_shop_pos_skip_transaction: boolean;
  read_shop_report_product: boolean;
  read_shop_pos_skip_transaction: boolean;
  read_shop_report_calendar: boolean;
  depot_catalogue_read: boolean;
  depot_order_write: boolean;
  read_stock: boolean;
  write_stock: boolean;
  read_shop_pos_report: boolean;
  partner_centre_home: boolean;
  partner_centre_inventory: boolean;
  partner_centre_orders: boolean;
  partner_centre_promotions: boolean;
  partner_centre_sales_reports: boolean;
  partner_centre_scan: boolean;
  partner_centre_wholesale: boolean;
  partner_centre_your_store: boolean;
}

export type Permission = keyof PermissionsLibrary;

export const pageAccessPermissionsByPriority = [
  'partner_centre_home',
  'partner_centre_inventory',
  'partner_centre_orders',
  'partner_centre_promotions',
  'partner_centre_sales_reports',
  'partner_centre_scan',
  'partner_centre_wholesale',
  'partner_centre_your_store',
] as const satisfies readonly Permission[];

type PageAccessPermission = (typeof pageAccessPermissionsByPriority)[number];

export const pageAccessPermissionRouteMap = {
  partner_centre_home: '/',
  partner_centre_inventory: '/products' + '/live',
  partner_centre_orders: '/orders' + '/live',
  partner_centre_promotions: '/promotions',
  partner_centre_sales_reports: '/reports' + '/store',
  partner_centre_scan: '/pos' + '/scan',
  partner_centre_wholesale: 'wholesale',
  partner_centre_your_store: '/your-store',
} as const satisfies Record<PageAccessPermission, string>;

export const checkIfAnyPageIsPermitted = (permissions: PermissionsLibrary) => {
  if (
    !permissions['partner_centre_home'] &&
    !permissions['partner_centre_inventory'] &&
    !permissions['partner_centre_orders'] &&
    !permissions['partner_centre_promotions'] &&
    !permissions['partner_centre_sales_reports'] &&
    !permissions['partner_centre_scan'] &&
    !permissions['partner_centre_wholesale'] &&
    !permissions['partner_centre_your_store']
  ) {
    return false;
  }
  return true;
};

export const getTheFirstPermittedPageRoute = (
  permissions: PermissionsLibrary
) => {
  if (permissions['partner_centre_home']) {
    return '/';
  }

  if (!checkIfAnyPageIsPermitted(permissions)) {
    return LOGIN_ROUTE;
  }

  for (const permission of pageAccessPermissionsByPriority) {
    if (!permissions[permission]) {
      continue;
    }
    return pageAccessPermissionRouteMap[permission];
  }
};
